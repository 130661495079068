<template>
    <div class="tag">
        <div class="search">
            <span>
                Searching for:
            </span>
            <span class="key">@{{searchKey}}</span>
        </div>
        <hr>
        <div class="users">
            <template v-for="(user, i) in tagAvailable" :key="i">
                <div class="user" :class="{select: selected === i}" @mouseenter="selected = i" @click="sendUser">
                    <figure>
                        <img v-if="user.userInfo.avatar" :src="imgURL + user.userInfo.avatar" alt="">
                        <span v-else class="no-img">{{user.userInfo.name.charAt(0)}}</span>
                    </figure>
                    <span>{{user.userInfo.name}}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        searchKey: {
            type: String,
            default: () => ''
        },
        users: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            selected: 0,
            imgURL: process.env.VUE_APP_DO_SPACES,
        }
    },

    computed: {
        tagAvailable() {
            return this.users.filter(el => el.userId !== this.user.id).filter(el => el.userInfo.name.toLowerCase().includes(this.searchKey.toLowerCase()))
        },
    },

    mounted() {
        window.addEventListener('keydown', this.handleKeys)
    },

    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeys)
    },

    methods: {
        handleKeys(e) {
            if (e.key === 'ArrowDown' && (this.selected < this.tagAvailable.length - 1)) this.selected += 1
            if (e.key === 'ArrowUp' && this.selected > 0) this.selected -= 1
            if(e.key === 'Enter') {
                this.sendUser()
            }
        },

        sendUser() {
            this.$emit('tagUser', {
                name: this.tagAvailable[this.selected].userInfo.name,
                id: this.tagAvailable[this.selected].userId
            })
        }
    }
}
</script>

<style lang="scss">
    .tag {
        position: absolute;
        background: #f2f5f8;
        padding: 20px;
        width: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        bottom: 75px;
        left: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        gap: .5rem;

        .search {
            .key {
                color: var(--primary-color);
            }
        }
        
        hr {
            border: none;
            height: 1px;
            width: 100%;
            background: white;
        }

        .users {
            display: flex;
            flex-direction: column;
            background: #cacccf52;
            max-height: 300px;
            overflow: auto;
            .user {
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 10px;

                &.select {
                    background: #a2a4a752;
                }

                figure {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
</style>