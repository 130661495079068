<template>
    <div class="attachments-wrapper">
        <div class="close" @click="$emit('close')">
            <img src="/assets/images/left-arrow.svg" alt="close_chevron">
        </div>
        <div class="title" @click="$emit('close')">Attachments and Media</div>
        <hr />
        <div class="items">
            <template v-if="files.length">
                <template v-for="(item, index) in files" :key="index">
                    <div class="item">
                        <img v-if="item.type==='image'" :src="item.url" alt="" @click="handleGallery(index)">
                        <img v-else class="doc" src="/assets/images/doc-icon.svg" alt="" @click="handleDoc(item.url)">
                    </div>
                </template>
            </template>
            <template v-else>
                <span class="empty">This Chat has no attachments</span>
            </template>
        </div>
        <gallery v-if="Object.keys(toGallery).length" :data="toGallery" @close="toGallery = {}"/>
    </div>
    
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_USER_PROFILE_GETTER } from '@/store/storeconstants';
import Gallery from './Gallery.vue'
import nodeInstance from '@/services/NodeServiceinstance';
export default {
    props: {
        room: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            files: [],
            currentPage: 1,
            toGallery: {}
        }
    },

    emits: ['close'],

    components: {
        Gallery
    },

    computed: {
        ...mapGetters('account', {
          getUserProfile: GET_USER_PROFILE_GETTER,
        }),
    },

    methods: {
        async getAttachments() {
            const response = await nodeInstance.get(`chats/attachments?room=${this.room}&page=${this.currentPage}&limit=50`)
			if(response.status === 200) this.files.push(...response.data.attachments)
        },

        handleGallery(sel) {
            this.toGallery = {
                items: this.files,
                init: sel
            }
        },

        handleDoc(data) {
            window.open(data)
        }
    },

    mounted() {
        this.getAttachments()
    }
}
</script>

<style lang="scss" scoped>
.attachments-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-left: solid 1px rgba(0,0,0,0.1);
    user-select: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .close {
        width: 10px;
        height: 10px;
        transform: rotate(270deg);
        cursor: pointer;
    }
    hr {
        border: none;
        width: 100%;
        height: 1px;
        background: rgba(0,0,0,0.1);
    }
    .items {
        display: flex;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        gap: 1%;
        .item {
            width: 32.3%;
            max-height: 80px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            .doc {
                width: 50%;
            }

            &:hover {
                background: rgba(0,0,0,0.1);
            }
        }

        .empty {
            width: 100%;
            height: 100%;
        }
    }
}
</style>