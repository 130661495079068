<template>
    <div class="attachments-wrapper">
        <div class="close" @click="$emit('close')">
            <img src="/assets/images/left-arrow.svg" alt="close_chevron">
        </div>
        <div class="title" @click="$emit('close')">Members</div>
        <hr />
        <div class="items">
            <template v-if="users.length">
                <template v-for="(item, index) in users" :key="index">
                    <div class="item">
                        <figure>
                            <img v-if="item.userInfo.avatar" :src="imgURL + item.userInfo.avatar" alt="avatar">
                            <span v-else>{{item.userInfo.name.charAt(0)}}</span>
                        </figure>
                        <span>{{item.userInfo.name}}</span>
                    </div>
                </template>
            </template>
            <template v-else>
                <span class="empty">This Chat has no users</span>
            </template>
        </div>
    </div>
    
</template>

<script>
import nodeInstance from '@/services/NodeServiceinstance';
export default {
    props: {
        room: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            users: [],
            currentPage: 1,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            imgURL: process.env.VUE_APP_DO_SPACES,
        }
    },

    emits: ['close'],

    methods: {
        async getUsers() {
            const response = await nodeInstance.get(`/chats/roomDetails/${this.room}`)
			if(response.status === 200) this.users.push(...response.data.users)
            console.log(this.users)
        },
    },

    mounted() {
        this.getUsers()
    }
}
</script>

<style lang="scss" scoped>
.attachments-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-left: solid 1px rgba(0,0,0,0.1);
    user-select: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .close {
        width: 10px;
        height: 10px;
        transform: rotate(270deg);
        cursor: pointer;
    }
    hr {
        border: none;
        width: 100%;
        height: 1px;
        background: rgba(0,0,0,0.1);
    }
    .items {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: .5rem;
        .item {
            width: 100%;
            max-height: 80px;
            display: flex;
            align-items: center;
            background: white;
            gap: 1rem;
            border-bottom: solid 1px rgba(0,0,0,0.1);
            padding: 0 1rem .5rem;

            figure {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--primary-color);
                    color: white;
                    font-weight: 500;
                }
            }

            span {
                color: var(--primary-color);
            }
            
        }

        .empty {
            width: 100%;
            height: 100%;
        }
    }
}
</style>