<template>
  <div class="chat-info" :class="{ ticket: ticket }">
    <div class="general-info">
      <figure>
        <img v-if="info.product.product_images.length"
          :src="imgURL + info.product.product_images[0].image"
          alt="ticket image"
        />
        <span v-else>
            {{info.title.charAt(0)}}
        </span>
      </figure>
      <span class="name">{{
        info.title
      }}</span>
    </div>
    <hr />
    <div class="options">
      <template v-for="(item, index) in filteredExtras" :key="index">
        <div class="item" v-if="item.handler === 'search'">
          <input
            type="text"
            v-model="searchkey"
            :placeholder="item.title"
          />
          <i class="fa fa-search searchIcon" @click="handler(item.handler)"></i>
        </div>
        <div
          v-else
          class="item"
          :class="{ delete: item.handler.includes('delete') }"
          @click="handler(item.handler)"
        >
          <span>{{ item.title }}</span>
        </div>
      </template>
    </div>
    <group-popup
      v-if="group"
      :users="true"
      @close="group = false"
      @data="handleCreateGroup"
    />
    <transition name="slide-up">
      <attachments v-if="attachs" :room="room" @close="attachs = false" />
    </transition>
    <transition name="slide-up">
      <members v-if="members" :room="room" @close="members = false" />
    </transition>
  </div>
</template>

<script>
import extras from "@/assets/json/chatextras.json";
import groupPopup from "@/components/Chat/GroupPopup.vue";
import Attachments from "./Attachments.vue";
import Members from "./Members.vue";
export default {
  props: {
    info: {
      type: Object,
      required: true,
    },
    ticket: {
      type: Boolean,
      required: true,
    },
    room: {
      type: String,
      required: true,
    },
    canEdit: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    type: {
      required: false,
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      user: localStorage.getItem("U_P")
        ? JSON.parse(localStorage.getItem("U_P"))
        : {},
      searchkey: "",
      group: false,
      organization_details: JSON.parse(localStorage.getItem("organization")),
      attachs: false,
      members: false
    };
  },

  components: {
    groupPopup,
    Attachments,
    Members
  },

  computed: {
    filteredExtras() {
        if(this.type.length && (this.type[0] === 'support' || this.type[0] === 'internal')) return extras["extra-ticket"];

        if (!this.canEdit && this.ticket) return extras["user-ticket"];

        if (this.ticket) return extras.ticket;
        
        return extras.chat;
    },
  },

  watch: {
    searchkey(val) {
      this.$emit("searchkey", val);
    },
  },

  mounted() {
    console.log(this.type)
  },

  methods: {
    handler(data) {
      switch (data) {
        case "delete_chats":
          this.handleDeleteChats();
          break;
        case "group":
          this.group = true;
          break;
        case "users_in_chat":
          this.members = true;
          break;
        case "attachments":
          this.attachs = true;
          break;
        case "support_chat":
          this.$emit("supportChat");
          break;
        case "add_user":
          this.$emit("add");
          break;
        case "edit_ticket":
          this.$emit("editTicket");
          break;

        default:
          break;
      }
    },

    handleDeleteChats() {
      const data = {
        room: this.room,
      };
      this.emitter.emit("delete-chats", data);
    },

    handleCreateGroup(data) {
      let usersToAdd = this.info.users.map((el) => el.userId);
      usersToAdd.push(...data.users.map((el) => el.userId));
      const params = {
        organizationId: this.organization_details.id,
        participants: data.users.map((el) => el.userId),
        type: "group",
        groupImage: data.image,
        groupName: data.name,
      };
      this.emitter.emit("create-room", params);
      this.emitter.emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-up-enter-active {
  animation: slide-up 0.5s;
}
.slide-up-leave-active {
  animation: slide-up 0.5s reverse;
}
.chat-info {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: #f2f5f871;
  position: relative;
  &.ticket {
    width: 50%;
  }
  .general-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    figure {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      span {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--primary-color);
        font-weight: 500;
        color: white;
        font-size: 1.2rem;
      }
    }
    .name {
      font-weight: 500;
      font-size: 1.2rem;
    }
  }

  hr {
    border: none;
    height: 2px;
    background: #f2f5f8;
    width: 70%;
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;

    .item {
      width: 100%;
      padding: 10px;
      background: #f2f5f8;
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      transition: 0.2s;
      border-radius: 5px;

      input {
        background: transparent;
        border: none;
        width: 90%;
      }

      .searchIcon {
        width: 10%;
        color: #8b8b8b;
      }

      &.delete {
        background: #ed0423;
        color: white;

        &:hover {
          background: #e00421;
        }
      }

      &:hover {
        background: #dfe0e2;
      }
    }
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
    overflow: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    overflow: hidden;
  }
}
</style>