<template>
    <div class="chat-bar" @drop="drop">
        <div class="more" @click="more = !more">
            <label>
                <input ref="import" type="file" accept="*" @change="importFiles">
                <img src="/assets/images/plus-icon.png" alt="import image">
            </label>
        </div>
        <div class="contain">
            <div class="backdrop" ref="msg" v-html="msgHTML"></div>
            <input type="text" class="bar" placeholder="Write a text..." @paste.prevent="handlePaste" @keyup.prevent="isTyping" @keyup.enter="sendMessage" v-model="msg" />
        </div>
        <div class="send"><img src="/assets/images/send_icon.svg" alt="send icon" @click="sendMessage"></div>
        <div class="attachments" :class="{show: more}">
            <div v-if="attachments.length" class="images">
                <div class="file" v-for="(img,index) in images" :key="index">
                    <img :src="img.link" alt="">
                    <div class="delete" @click="removeImage(index)">X</div>
                </div>
            </div>
        </div>
        <TagUser v-if="tagUser" :search-key="toSearch" :users="users" @tagUser="handleTag"/>
    </div>
    <attachment v-if="attachments.length" :items="attachments" @send="sendFiles" />
</template>

<script>
import { mapActions } from "vuex";
import { UPLOAD_CHAT_ATTACHMENT } from "@/store/storeconstants";
import Attachment from './AttachmentPop.vue'
import TagUser from './TagUser.vue'
import nodeInstance from '@/services/NodeServiceinstance';
export default {
    props: {
        roomId: {
            required: false
        },
        fromDrop: {
            required: false,
            type: Array
        },
        messagesSize: {
            required: false
        }
    },

    data() {
        return {
            msg: '',
            more: false,
            attachments: this.fromDrop || [],
            tagUser: false,
            toSearch: '',
            users: [],
            msgHTML: '',
            toHighlight: [],
            taggedUsers: []
        }
    },

    watch: {
        toHighlight: {
            deep: true,
            handler(val) {
                let msg = this.msg

                val.forEach(el => {
                    msg = msg.replace(`@${el.name}`, `<span class="highlight">@${el.name}</span>`)
                })

                this.msgHTML = msg
            }
        },

        msg(val) {
            this.toHighlight.forEach((el, i) => {
                if (!val.includes(`@${el.name}`)) {
                    this.toHighlight.splice(i, 1)
                    this.msg = this.msg.replace(`@${el.name.slice(0, -1)}`, '')
                }
            })
            
        }
    },

    components:{
      Attachment,
      TagUser
    },
    
    emits: ['sentMessage'],

    created() {
        this.availableUsers()
    },

    methods: {
        ...mapActions("chat", {
            updateChatFile: UPLOAD_CHAT_ATTACHMENT,
        }),

        sendMessage() {
            if (!this.tagUser) {
                if(this.msg){
                    const params = {
                        roomId: this.roomId,
                        text: this.msg,
                        parentId: null,
                        mentionedUsers: this.toHighlight
                    };
                    this.emitter.emit("send-message", params);
                    this.$emit('sentMessage')
                }
                this.msg = '';
            }
            
        },

        async availableUsers() {
            const response = await nodeInstance.get(`/chats/roomDetails/${this.roomId}`)
			if(response.status === 200) this.users.push(...response.data.users)
        },

        handlePaste(data) {
            const items = data.clipboardData.items
            
            if (items[0].kind === 'file') {
                const file = {target:{
                    files: [items[0].getAsFile()]
                }}
                this.importFiles(file)
            }
        },

        async importFiles(event) {
            const file = event.target.files[0]

            if (file.type.match(/image.*/)) {
                const fr = new FileReader();
                fr.readAsDataURL(file);
                fr.onload = () => {
                    this.attachments.push({
                        original: file,
                        link: fr.result
                    })
                }
            }
            else (
                this.attachments.push({
                    original: file,
                })
            )
        },
        drop(e){
            e.stopPropagation();
            e.preventDefault();
            const files = e.dataTransfer.files

            files.forEach(file => {
                if (file.type.match(/image.*/)) {
                    const fr = new FileReader();
                    fr.readAsDataURL(file);
                    fr.onload = () => {
                        this.attachments.push({
                            original: file,
                            link: fr.result
                        })
                    }
                }
                else (
                    this.attachments.push({
                        original: file,
                    })
                )
            });
        },

        async sendFiles(data){
            let params = {
                    attachment: data.files,
                    text: data.message || '',
                    room: this.roomId,
                };

            this.attachments = []

            await this.updateChatFile(params)
        },

        handleTag(user) {
            console.log(user)
            this.msg = this.msg.replace(`@${this.toSearch}`, `@${user.name}`)

            const word = `@${user.name}`

            const needed = {
                id: user.id,
                name: user.name,
                start: this.msg.indexOf(word),
                end: this.msg.indexOf(word) + (word.length - 1),
                _id: this.roomId
            }

            
            this.toHighlight.push(needed)

            setTimeout(() => {
                this.atUser()
            }, 200);
        },
    

        isTyping(e) {
            if(e.key === '@') this.atUser()

            if (this.msg.includes('  ')) {
                this.msg = this.msg.replace('  ', ' ')
                this.atUser()
            }

            if (this.msg.includes('@@')) {
                this.msg = this.msg.replace('@@', '')
            }

            if (!this.msg.includes('@')) this.tagUser = false
            
            this.toSearch = this.msg.split('@').pop()

            const params = {
                roomId: this.roomId,
            }
            this.emitter.emit("is-typing", params);
        },

        atUser() {
            if (this.tagUser) {
                this.tagUser = false
                this.toSearch = ''
                return
            }

            this.tagUser = true
        }
    },
}
</script>

<style lang="scss" scoped>
.chat-bar {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    position: relative;
    border-top: solid 1px #F2F5F8;

    @media only screen and (max-width: 1200px) {
        width: calc(100% - 40px);
    }

    div {
        user-select: none;
        cursor: pointer;
    }
    .more {
        display: flex;
        justify-content: center;
        align-items: center;
        label {
                width: 20px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                transition: 0.2s;

                input {
                    display: none;
                }

                img {
                    width: 100%;
                    transition: 0.2s;
                }
            }
    }
    .contain {
        width: calc(100% - 40px);
        background: #F2F5F8;
        display: flex;
        align-items: center;
        

        input {
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 0.8rem;
            background: transparent;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .backdrop {
            padding: 10px 20px;
            position: absolute;
            color: transparent;
            user-select: none;
            font-size: 0.8rem;
            z-index: 0;
            width: 100%;
        }
    }
    .send {
        width: 20px;
        img {
            width: 100%;
        }
    }
    .attachments {
        position: absolute;
        top: 0;
        transition: 0.2s;
        display: none;

        &.show {
            display: flex;
            animation: show 0.2s forwards;
        }
    }
}


@keyframes show {
    from {
        top: -15px;
        opacity: 0;
    }
    to {
        top: -45px;
        opacity: 1;
    }
}
</style>

<style lang="scss">
.highlight {
    color: transparent;
    border-radius: 3px;
    background-color: #abe5e9;
}
</style>
